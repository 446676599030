import React, { useState } from 'react';
import './OnlineApplicationForm.css';
import logo from '../../assests/logos/best_logo_.png';
import axios from 'axios';

const ApplyJobForm = () => {

const[fname, setFname]= useState("")
const[lname, setLname]= useState("")
const[position, setPostion]= useState("")
const[grade,setGrade] = useState("")
const[contactNumber, setContactNumber] = useState("")
const[email, setEmail] = useState("")
const[file, setFile] = useState(null)



  const [result, setResult] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('fname ', fname)
    formData.append('lname ',lname)
    formData.append('position', position)
    formData.append('contactNumber ', contactNumber)
    formData.append('grade', grade)
    formData.append('email', email)
    formData.append('file', file)

    console.log(formData)
    setResult("Sending...")
     try {
      const response = await axios.post(
        'https://backend-api.pinkroses.sc.ke/api/job-application-form/submit',
        formData,
     
      );
      if (response.status === 201){
        console.log(email,grade,contactNumber,fname,lname)
        setResult('Application submitted successfully!');
        console.log("Data sent: ", formData)
        setFname('')
        setLname('')
        setEmail('')
        setGrade('')
        setPostion('')
        setFile('')
        setContactNumber('')
     
      }
    } catch (error) {
      setResult('Failed to submit application.');
    }
  };

  return (
    <div className="application-form">
      <img src={logo} alt="Pink Roses Schools Logo" className="logo" />
      <h2>Pink Roses Schools Job Application Form</h2>
      <form onSubmit={handleSubmit}   enctype="multipart/form-data">
        <div className="form-group">
          <label htmlFor="fname">First Name</label>
          <input
            type="text"
            id="fname"
            name="fname"
            value={fname}
            onChange={(e)=>setFname(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="lname">Last Name</label>
          <input
            type="text"
            id="lname"
            name="lname"
            value={lname}
            onChange={(e)=>setLname(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="position">Position Applying for</label>
          <input
            type="text"
            id="position"
            name="position"
            value={position}
            onChange={(e)=>setPostion(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="grade">Level of Education</label>
          <input
            type="text"
            id="grade"
            name="grade"
            value={grade}
            onChange={(e)=>setGrade(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="contactNumber">Contact Number</label>
          <input
            type="text"
            id="contactNumber"
            name="contactNumber"
            value={contactNumber}
            onChange={(e)=>setContactNumber(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Your email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={(e)=>setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="file">Upload cv, pdf format only</label>
          <input
            type="file"
            id="file"
            name="file"

            onChange={(e)=> setFile(e.target.files[0])}
            required
          />
        </div>
        <button type="submit">Apply</button>
      </form>
      <span>{result}</span>
    </div>
  );
};

export default ApplyJobForm;
