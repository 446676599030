import React from "react";
import  './Titles.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faUserPlus  } from '@fortawesome/free-solid-svg-icons'



const Titles  = ( {title})=>{

return(
    <div className="title">
      <div className="icon">
          <FontAwesomeIcon icon={faUserPlus} />
      </div>
       <div className="heading">
       <h1> {title}</h1>
       
       </div>
      
        
       
    </div>
  )
}

export default Titles