// eslint-disable-next-line no-unused-vars
import React from 'react'
 
import './Hero.css'
// import dark_arrow from  ''
const Hero = () =>{
  return (
    <div className='hero container'>

        <div className='hero-txt'>
  
        </div>
    </div>
  )
}

export default Hero