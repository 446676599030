import React from 'react'
import './About.css'
import TitleWithoutIcon from '../../components/Home/Titles/TitleWithoutIcon'
import Values from '../../components/Home/Values/ValuesComponents'
import Titles from '../../components/Home/Titles/Titles'
import Programs from '../../components/Home/Programs/programs'
// import SchoolHistoryComponent from '../../components/SchoolHistoryComponent/SchoolHistoryComponent'
import AboutSchoolComponent from '../../components/AboutSchoolComponent/AboutSchoolComponent'
import EnquireComponent from '../../components/EnquireButton/EnquireComponent'

let About =()=>{
    return(
       <>
          <div className='about container'>
         <div className="about-us">
         <h1>Who we are</h1>
         </div> 
        </div> 
        {/* <TitleWithoutIcon title="Pink Roses  History"/> */}
        <AboutSchoolComponent/>
        <Values/>
       
        <EnquireComponent/>
       </>
        
    )
}

export default About