import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import Dropdown from '../Dropdown/Dropdown';
import AdmissionDropown from '../../Dropdowns/AdmissionDropown/AdmissionDropDown'
import EducationDropDown from '../../Dropdowns/EducationDropDown/educationDropDown'
import logo from '../../../assests/logos/logo_white.png';
import logo1 from '../../../assests/logos/horizontal_logo.png'
import ExternalPortals from '../../Dropdowns/ExternalLinks/PortalsDropDown';
import Button from '../../Button/Button';
const Navbar = () => {
  const [sticky, setSticky] = useState(false);
  const [click, setClick] = useState(false);
  const [dropdownSchool, setDropdownSchool] = useState(false);
  const [dropdownAdmissions, setDropdownAdmissions] = useState(false);
  const [dropdownAcademics, setDropdownAcademics] = useState(false);
  const [dropdownPortal, setDropDownPortal] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const onMouseEnterSchool = () => {
    if (window.innerWidth <= 1400) {
      setDropdownSchool(true);
    }
  };
  const onMouseLeaveSchool = () => {
    if (window.innerWidth <= 1400) {
      setDropdownSchool(false);
    }
  };
  const onMouseEnterAdmissions = () => {
    if (window.innerWidth <= 1400) {
      setDropdownAdmissions(true);
    }
  };
  const onMouseLeaveAdmissions = () => {
    if (window.innerWidth <= 1400) {
      setDropdownAdmissions(false);
    }
  };
  const onMouseEnterAcademics = () => {
    if (window.innerWidth <= 1400) {
      setDropdownAcademics(true);
    }
  };
  const onMouseLeaveAcademics = () => {
    if (window.innerWidth <= 1400) {
      setDropdownAcademics(false);
    }
  };
  // const onMouseEnterPortals = () => {
  //   if (window.innerWidth <= 1400) {
  //     setDropDownPortal(true);
  //   }
  // };
  // const onMouseLeavePortals = () => {
  //   if (window.innerWidth <= 1400) {
  //     setDropDownPortal(false);
  //   }
  // };

  useEffect(() => {
    const handleScroll = () => {
      setSticky(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup function
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <nav className={`nav container ${sticky ? 'dark-nav' : ''}`}>
  
        <Link to='/' onClick={closeMobileMenu}>
          <img src={sticky ? logo1 : logo} alt="LOGO" className='nav_logo' />
        </Link>
      
           {
        <div className='menu-icon' onClick={handleClick}>
          <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
        </div> }

        <ul className={click ? 'nav-menu active' : 'nav-menu'}>
          <li
            className='nav-item'
            onMouseEnter={onMouseEnterSchool}
            onMouseLeave={onMouseLeaveSchool}
          >
            <Link
              to='/'
              className={` ${sticky ? 'dark-nav-links' : 'nav-links'}`}
              onClick={closeMobileMenu}
            >
              OUR SCHOOL<i className='fas fa-caret-down' />
            </Link>
            {dropdownSchool && <Dropdown />}
          </li>
          <li
            className='nav-item'
            onMouseEnter={onMouseEnterAcademics}
            onMouseLeave={onMouseLeaveAcademics}
          >
            <Link
              to='/'
              className={` ${sticky ? 'dark-nav-links' : 'nav-links'}`}
              onClick={closeMobileMenu}
            >
              ACADEMICS<i className='fas fa-caret-down' />
            </Link>
            {dropdownAcademics && < EducationDropDown/>} 
          </li>

          <li
            className='nav-item'
            onMouseEnter={onMouseEnterAdmissions}
            onMouseLeave={onMouseLeaveAdmissions}
          >
            <Link
              to='/'
              className={` ${sticky ? 'dark-nav-links' : 'nav-links'}`}
              onClick={closeMobileMenu}
            >
              ADMISSIONS<i className='fas fa-caret-down' />
            </Link>
            {dropdownAdmissions && <AdmissionDropown />} {/* Dropdown for Admissions */}
          </li>
          <li className='nav-item'>
            <Link
              to='/school/boarding'
              className={` ${sticky ? 'dark-nav-links' : 'nav-links'}`}
              onClick={closeMobileMenu}
            >
              BOARDING
            </Link>
          </li>

          <li
            className='nav-item'
            // onMouseEnter={onMouseEnterPortals}
            // onMouseLeave={onMouseLeavePortals}
          >
            <a href="https://pra.mzizi.co.ke/ISIMSLogin.aspx" className={` ${sticky ? 'dark-nav-links' : 'nav-links'}`}>
                <p>PORTALS</p>
              </a>
           
          </li>
          <Button/>
        </ul>
      </nav>
    </>
  );
};

export default Navbar;
