import React from "react";
import './TermsAndServices.css';

const TermsAndServices = () => {
  return (
    <>
      <div className="privacy-sections">
        <div className="all-sections">
          <h1>DATA CONTROLLER:</h1>
          <p>
            PINK ROSES GROUP OF SCHOOLS Off Kiambu – Ngewa Road, Kiambu Pink
            Roses Schools (also referred as “the School”) is part of Pink Roses
            Limited, a company registered in Kenya and registered address P.O
            Box 6-00901, Ngewa in Kiambu county.
          </p>
        </div>

        <div className="all-sections">
          <h1>1. INTRODUCTION</h1>
          <p>
            Pink Roses Schools respect privacy of customer data and is committed
            to protecting and treating any personal information obtained
            confidentially and in accordance to the Data Protection Act No.24 of
            2019 (DPA) that came into force on 25th November, 2019. This policy
            is created with the aim to provide information to the data subjects
            about how the School processes the personal data.
          </p>
        </div>

        <div className="all-sections">
          <h1>2. TYPES OF PERSONAL DATA WE PROCESS</h1>
          <p>
            We process personal data from our current and former students and
            their families and/or legal representatives and also from our
            employees, candidates, suppliers, contractors, prospective customers
            and third parties interested in finding out more about our School
            within the systems controlled by the School as Data Controller. The
            personal data processed is mainly the following:
          </p>
          <ul>
            <li>Contact details that include names, addresses, telephone numbers and e-mail addresses</li>
            <li>Safeguarding information (such as court orders and professional involvement)</li>
            <li>Education and employment data</li>
            <li>Images, audio and video recordings, including CCTV footage (video images)</li>
            <li>Financial information</li>
            <li>Educational institutions previously attended</li>
            <li>Information about health status or provision of health care linked to a specific individual</li>
            <li>Behavioural information</li>
            <li>Special educational needs</li>
            <li>Criminal records linked to a specific individual</li>
            <li>Biometric information</li>
            <li>Ethnicity</li>
            <li>Religion</li>
          </ul>
        </div>

        <div className="all-sections">
          <h1>3. HOW WE COLLECT AND PROCESS PERSONAL DATA</h1>
          <p>The School collects information in several ways, including:</p>
          <ul>
            <li>Personally and over the phone</li>
            <li>Through our website</li>
            <li>Through cookies used on our website, through which we can collect login data, browsing behaviour, settings and actions in web applications</li>
            <li>From email and hand delivery paper documentation</li>
            <li>Through online tools, such as apps, educational platforms and other software used by the School</li>
            <li>Through any CCTV cameras located at our premises</li>
            <li>Through third parties, such as referees, professionals or authorities working with the individual</li>
            <li>Through photography and video recordings</li>
          </ul>
        </div>

        <div className="all-sections">
          <h1>4. PURPOSES FOR WHICH WE PROCESS PERSONAL DATA</h1>
          <p>
            The School processes personal data to lawfully and legitimately
            carry out the school’s educational activity. The school collects and
            keeps students and parents’ information when necessary:
          </p>
          <ul>
            <li>Attend your enquiries</li>
            <li>Ensure that the student meets the School’s admission criteria</li>
            <li>Provide educational services including the support of pupil learning, monitor and report on pupil progress</li>
            <li>Offer to the students welfare and pastoral care services</li>
            <li>Provide to the students medical and nurse attention, school uniform, catering service and bus routes, among other necessary services</li>
            <li>Meet the educational, social, physical and emotional requirements of the student</li>
            <li>Celebrate Alumni’s activities</li>
            <li>Enable parents/guardians to be contacted in the case of emergency or in the case of school closure, or to inform parents of their child’s educational progress or about school events</li>
            <li>Celebrate School’s achievements and to keep a memory of our students. In this case we may take and use photographs and audio-visual recordings to be shared on our websites and social networks, always based on informed consent from parents or students of legal age</li>
            <li>Send commercial communications. You will be able to unsubscribe from the receipt of such communications by informing the school through the email: dpo@pinkroses.ac.ke</li>
          </ul>
        </div>

        <div className="all-sections">
          <h1>5. PROCESSING OF CANDIDATES DATA</h1>
          <p>
            Personal information and CV uploaded on our website under “Careers”
            section will be used for purpose of evaluating your qualification
            for job positions. Our Human Resources department will process your
            data with the purpose to include your candidacy in our selection
            processes.
          </p>
        </div>

        <div className="all-sections">
          <h1>6. LEGAL BASIS FOR DATA PROCESSING</h1>
          <p>
            The legal basis that supports the collection and processing of your
            data are the following:
          </p>
          <ul>
            <li>
              Execution of a contractual relationship of which the data subject is a party: The admission and registration of students, the provision of educational, academic, nursing, safeguarding and other related services, as well as the sending of communications relating to teaching activities and the administrative and billing management necessary for the provision of the aforementioned services
            </li>
            <li>
              Compliance with our legal obligations: We must manage and maintain the data of students' parents, employees and suppliers to comply with the legal requirements of the relevant authorities, including the management of legal claims
            </li>
            <li>
              Vital interest: If we have to protect someone’s life or health, we will take immediate action to preserve them
            </li>
            <li>
              Your consent: We may need the consent of the parents/legal guardians or the student over legal age to send commercial communications, as well as for the creation and use of images and videos to disclose the student’s participation in our activities or for promotional campaigns
            </li>
          </ul>
        </div>

        <div className="all-sections">
          <h1>7. WITH WHOM DO WE SHARE YOUR PERSONAL DATA?</h1>
          <p>
            As a School, we may be lawfully required to share personal
            information with relevant authorities or third parties as permitted
            on statutory grounds, to comply with legal obligations or on the
            basis of a specific consent given by the individual concerned.
          </p>
          <p>
            In the case of educational or extra-curricular activities, your data
            may be communicated to third parties involved in the rendering of
            the services. We will ensure that, where relevant, contractual
            safeguards are implemented to ensure the protection of your personal
            data when disclosing them to a third party.
          </p>
          <p>
            Out of the cases previously described, no data will be communicated
            to third parties. Your personal data shall be neither rented nor
            sold to third parties under any concept.
          </p>
        </div>

        <div className="all-sections">
          <h1>8. INTERNATIONAL DATA TRANSFER</h1>
          <p>
            We will obtain express consent from you for sharing your personal
            information to parties outside Kenya especially where such
            jurisdictions have weak data privacy laws.
          </p>
        </div>

        <div className="all-sections">
          <h1>9. CONSERVATION OF DATA</h1>
          <p>
            Personal information will only be retained for the period required
            to fulfil the purpose for which it was collected unless for a longer
            time for public interest archiving, scientific or historical
            research, or statistical purposes. Once the personal information is
            no longer required or permitted to be retained for legal or business
            purposes, it will be safely destroyed or made anonymous.
          </p>
        </div>

        <div className="all-sections">
          <h1>10. YOUR RIGHTS</h1>
          <p>You have the right to:</p>
          <ul>
            <li>Be informed, at the time when personal data is collected, about the data we are processing about you</li>
            <li>Access the information we process and how we process it as well as to obtain a copy of the personal data considering the legal limitations and exceptions</li>
            <li>Obtain the correction of your personal data providing a supplementary statement</li>
            <li>Ask for the deletion of false or misleading data about you</li>
            <li>Object to processing for direct marketing purposes</li>
          </ul>
          <p>
            The School will reply to your request without undue delay from the
            receipt of your request according to the legal terms. You can
            exercise your rights by sending a written request together with your
            ID card to our Data Protection Officer at:{" "}
            <a href="mailto:dpo@pinkroses.ac.ke">dpo@pinkroses.ac.ke</a>
          </p>
        </div>

        <div className="all-sections">
          <h1>11. CONSENT</h1>
          <p>
            You will have the right to withdraw your consent for any specific
            purpose granted at any given time without prejudice of the legality
            of processing according to the consent given before withdrawal.
          </p>
        </div>

        <div className="all-sections">
          <h1>12. STORING AND SECURING INFORMATION</h1>
          <p>
            The School applies the appropriate technical and organizational
            measures to ensure the security, confidentiality, integrity and
            privacy of your personal data, preventing unauthorized access
            or unlawful processing as well as accidental loss, destruction or
            damage of the data.
          </p>
        </div>

        <div className="all-sections">
          <h1>13. COMMON USE WITH SOCIAL NETWORKS</h1>
          <p>
            This website contains links to other websites such as social
            networks like Instagram, Facebook, and LinkedIn. Our School is not
            responsible for the privacy practices or the content of such sites.
            You should be aware that the processing of your personal information
            is carried out by those sites and governed by their respective
            privacy policies. If you do not want these sites to collect and
            process your information, please review their corresponding privacy
            policies and/or disconnect from them before using our Services.
          </p>
        </div>

        <div className="all-sections">
          <h1>14. COOKIES USED IN OUR WEBSITE</h1>
          <p>
            We use cookies to provide you with a better experience on our
            website, as well as to store information about our visitors. This
            information is related to your session ID and helps in monitoring
            single user access. The web server is allowed to remember some data
            concerning the user, such as preferences for visiting the pages,
            name and password, and products of interest.
          </p>
          <p>
            The cookies we use on our website that require informed consent by
            the user are functional cookies and targeting cookies. Consent is
            not required for required cookies, which are technical in nature
            and necessary for the operation of the website or the provision of
            services expressly requested by the user.
          </p>
        </div>

        <div className="all-sections">
          <h1>15. COMPLAINTS</h1>
          <p>
            If you are not satisfied with our response to your request, would
            like to discuss anything regarding this privacy notice, or believe
            we are processing your personal data disregarding the data protection
            regulations in force, please contact our Data Protection Officer at:{" "}
            <a href="mailto:dpo@pinkroses.ac.ke">dpo@pinkroses.ac.ke</a>
          </p>
        </div>

        <div className="all-sections">
          <h1>16. PRIVACY NOTICE UPDATES</h1>
          <p>
            The School may need to update this privacy notice periodically so we
            recommend that you review this information from time to time. This
            version was last updated in June 2024.
          </p>
        </div>
      </div>
    </>
  );
};

export default TermsAndServices;