import React from "react";
import "./AboutSchoolComponent.css";
import AboutImg from "../../assests/Home/91.jpg";
const AboutSchoolComponent = () => {
  return (
    <>
      <div className="about-school">
        <div className="about-school-section">
          <h1>Who we are :</h1>
          <p>
            Pink Roses School started in 2001 with less than 50 pupils and has
            grown to be a home to more than 1000 pupils and students. We are a
            private mixed day and boarding primary and Junior Secondary School
            that is founded on strong Christian values and principles. We offer
            Competency Based Curriculum (CBC) and invested in state of art
            laboratories, computer labs, music rooms, home science and
            classrooms with smart boards. The school has made commendable
            achievements over the years. In the area of academic outcomes, we
            have grown to be a high performing school in Kiambu County and the
            larger Nairobi area attracting students from all over country.
          </p>
          <p>
            Set in the leafy areas of Kiambu, we have consistently performed
            well in national examinations. Our pupils have qualified for
            secondary school placement in National and County schools and gone
            on to graduate from the universities. In the Co-Curricular arena,
            our children have excelled in sports and drama competitions. We have
            an active scouts troupe. These achievements would not have been
            possible without the strong support, commitment and dedication of
            our teachers and parents.
          </p>
          <p>
            We are about guiding children to harness their individual strengths,
            build strong character and be ready to offer solutions and
            leadership in this modern world.
          </p>
        </div>

        <div className="history-right">
          <img className="history-img" src={AboutImg} alt="" />
        </div>
      </div>
    </>
  );
};

export default AboutSchoolComponent;
