import React from "react";
import  './HomeTitles.css'
import { faIconName } from '@fortawesome/free-solid-svg-icons';

const HomeTitles  = ( {title})=>{

return(
    <div className="home-title">
    
       <div className="home-heading">
       <h1> {title}</h1>
       </div>
     
    </div>
  )
}

export default HomeTitles