import React, { useState } from "react";
import "./SchoolHistoryComponent.css";

import playIcon from "../../assests/icons/play-icon.png";
import studentImage from "../../assests/Home/64.jpg";


let SchoolHistoryComponent = ({setPlayerState}) => {
  return (
    <>
      <div className="history-1">
        <div className="history-left-1">
          <img className="history-img-1" src={studentImage} alt="" />
          <img  className="play-icon" src={playIcon} alt="" onClick={ () => 
            {setPlayerState(true)}}/>
        </div>
        <div className="history-right-home">
          <h1>Welcome To Pink Roses Schools</h1>
          <p>
            Pink Roses Schools encompass a Private Primary and Junior Secondary
            School that are home to heroes and heroines on a life journey to
            achieving their educational dreams. We are not just a school but
            home away from home tucked in a serene green environment offering a
            conducive environment for learning. We are located away from town in
            an agricultural area enabling us to focus on our studies and also
            grow our own food!
          </p>
          <p>
            The school is founded on Christian principles and has a strong
            heritage of academic excellence. Founded in 2001, we draw our
            strengths from God and strive to instill Godly values to our
            children. We are called to excel in all our endeavors as we strive
            to be the school of choice for your child. Our bible guiding verse
            is Hosea 4:6- my people perish from lack of knowledge. Our desire is
            to instill Godly wisdom as we mentor and teach our children.
          </p>
          <p>
            Our teachers are experts in their field and our curriculum is
            designed to help students and pupils grow and reach their potential.
            We offer a wide range of programs designed to foster creativity,
            develop critical thinking skills and encourage collaboration. From
            Kindergarten to Junior Secondary school, we have something for
            everyone and are dedicated to creating an inclusive and respectful
            atmosphere.
          </p>
          <p>
            Our alumni are distinguished members of society who attest to the
            strong foundations they got from Pink Roses. Ours is a partnership
            that culminates in disciplined and self-confident pupils and
            students ready to make a difference in the society.
          </p>
        </div>
       
      </div>
    </>
  );
};

export default SchoolHistoryComponent;
