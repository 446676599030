import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './NewsEventEditForm.css'; // Ensure this file contains styles for the form

const NewsEventEditForm = ({ event, onClose, onEventUpdated }) => {
  const [updatedEvent, setUpdatedEvent] = useState(event);
  const [error, setError] = useState(null);

  useEffect(() => {
    setUpdatedEvent(event); // Set the event data when the form opens
  }, [event]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedEvent({ ...updatedEvent, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(`https://localhost:5000/api/admin/events/${updatedEvent.id}`, updatedEvent);
      onEventUpdated(response.data);  // Notify parent component about the updated event
      onClose();  // Close the form
    } catch (error) {
      setError('Error updating event');
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="btn-close" onClick={onClose}>X</button>
        <h2>Edit Event</h2>
        <form onSubmit={handleSubmit} className="news-event-form">
          <div className="form-group">
            <label htmlFor="title">Title:</label>
            <input
              type="text"
              id="title"
              name="title"
              value={updatedEvent.title}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="description">Description:</label>
            <textarea
              id="description"
              name="description"
              value={updatedEvent.description}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="date">Date:</label>
            <input
              type="date"
              id="date"
              name="date"
              value={updatedEvent.date}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="image">Image upload:</label>
            <input
              type="text"
              id="image"
              name="image"
              value={updatedEvent.image}
              onChange={handleInputChange}
              required
            />
          </div>
          <button type="submit" className="btn btn-primary">Update Event</button>
          {error && <p className="error-message">{error}</p>}
        </form>
      </div>
    </div>
  );
};

export default NewsEventEditForm;
