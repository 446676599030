import React from "react";
import  './TitlesWithoutIcon.css'


const TitleWithoutIcon  = ( {title})=>{

return(
    <div className="home-title">
       <div className="heading-title">
       <h1> {title}</h1>
      
       </div>   
    </div>
  )
}

export default TitleWithoutIcon