import React, { useState } from 'react';
import axios from 'axios';
import './NewsEventCreateForm.css'; // Import the CSS file for styling

const NewsEventCreateForm = () => {
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        date: '',
        image: null
      });
      const [errors, setErrors] = useState({});
      const [isSubmitting, setIsSubmitting] = useState(false);
    
      // Handle input changes
      const handleChange = (e) => {
        const { name, value, type, files } = e.target;
        setFormData({
          ...formData,
          [name]: type === 'file' ? files[0] : value
        });
      };
    
      // Validate form data
      const validate = () => {
        const newErrors = {};
        if (!formData.title) newErrors.title = 'Title is required';
        if (!formData.description) newErrors.description = 'Description is required';
        if (!formData.date) newErrors.date = 'Date is required';
        if (formData.image && formData.image.size > 2 * 1024 * 1024) // 2MB limit
          newErrors.image = 'Image size should not exceed 2MB';
    
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
      };
    
      // Handle form submission
      const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validate()) return;
    
        setIsSubmitting(true);
        const formDataToSend = new FormData();
        formDataToSend.append('title', formData.title);
        formDataToSend.append('description', formData.description);
        formDataToSend.append('date', formData.date);
        formDataToSend.append('image', formData.image);
    
        try {
          await axios.post('https://backend-api.pinkroses.sc.ke/api/news-events/create', formDataToSend, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
          alert('News event created successfully');
          setFormData({
            title: '',
            description: '',
            date: '',
            image: null
          });
        } catch (error) {
          console.error('Error creating news event:', error);
        } finally {
          setIsSubmitting(false);
        }
      };
    
      return (
        <div className="news-event-form">
          <h2>Create News Event</h2>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="title">Title</label>
              <input
                type="text"
                id="title"
                name="title"
                value={formData.title}
                onChange={handleChange}
                className={errors.title ? 'error' : ''}
              />
              {errors.title && <p className="error-message">{errors.title}</p>}
            </div>
            <div className="form-group">
              <label htmlFor="description">Description</label>
              <textarea
                id="description"
                name="description"
                value={formData.description}
                onChange={handleChange}
                className={errors.description ? 'error' : ''}
              />
              {errors.description && <p className="error-message">{errors.description}</p>}
            </div>
            <div className="form-group">
              <label htmlFor="date">Date</label>
              <input
                type="date"
                id="date"
                name="date"
                value={formData.date}
                onChange={handleChange}
                className={errors.date ? 'error' : ''}
              />
              {errors.date && <p className="error-message">{errors.date}</p>}
            </div>
            <div className="form-group">
              <label htmlFor="image">Image</label>
              <input
                type="file"
                id="image"
                name="image"
                onChange={handleChange}
                className={errors.image ? 'error' : ''}
              />
              {errors.image && <p className="error-message">{errors.image}</p>}
            </div>
            <button type="submit" disabled={isSubmitting}>
              {isSubmitting ? 'Sending...' : 'Submit'}
            </button>
          </form>
        </div>
      );
    };
    


export default NewsEventCreateForm