export const SchoolItems = [
 
    {
      title: 'Who we are',
      path: '/our-school/who-we-are',
      cName: 'dropdown-link'
    },
    {
      title: 'Our Philosophy',
      path: '/school/our-philosophy',
      cName: 'dropdown-link'
    },
 
    {
      title: 'Careers',
      path: '/our-school/careers',
      cName: 'dropdown-link'
    },
    {
      title: 'News & Events',
      path: '/school/newscreate',
      cName: 'dropdown-link'
    },
    {
      title: 'Alumni',
      path: '/school/alumni',
      cName: 'dropdown-link'
    }
  ];