import React from 'react'
import  '../Careers/Careers.css'
import OnlineApplicationForm from '../../components/Modal/OnlineApplicationForm'

const OnlineApplication = () => {
  return (
  <>
       <div className="about container">
        <div className="about-us">
          <h1>Register online.</h1>
        </div>
      </div>
 <OnlineApplicationForm/>
  </>
    
  )
}

export default OnlineApplication