import React from 'react'
import './Lines.css'
const Lines = () => {
  return (
    <div>
        <hr className='line'/>
    </div>
  )
}

export default Lines