import React from 'react'

import './AdmisionProcess.css'
import TitleWithoutIcon from '../../components/Home/Titles/TitleWithoutIcon'
import AdmissionComponent from '../../components/AdmissionProcess/AdmissionComponent'
import EnquireComponent from '../../components/EnquireButton/EnquireComponent'
const AdmissionProcess = () => {
  return(
    <>
       <div className='admission-process container'>
      <div className="choose-us">
      <h1> Our Admission Process</h1>
      </div> 
     </div> 
     <TitleWithoutIcon title="Steps to registration: " />
     <AdmissionComponent/>

    <EnquireComponent/>
     </>
     
 )
   
}

export default AdmissionProcess


    
