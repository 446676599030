import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './AdminPage.css';
import NewsEventCreateForm from '../../components/Modal/NewsEventCreateForm';
import NewsEventEditForm from '../../components/Modal/NewsEventEditForm';
import loadingGif from '../../assests/videos/loading.gif'; // Adjust the path if necessary
import { GiTrumpet } from 'react-icons/gi';

const AdminPage = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [currentEvent, setCurrentEvent] = useState(null);
  const [showLoading, setShowLoading] = useState(false); 

  // Fetch all events
  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get('https://backend-api.pinkroses.sc.ke/api/news-events');
        setLoading(GiTrumpet);
        setEvents(response.data);
      } catch (error) {
        setError('Error fetching events');
      } finally {
        setLoading(false);
        setShowLoading(false);
      }
    };

    fetchEvents();
  }, []);

  // Handle deleting an event
  const handleDeleteEvent = async (event_id) => {
    try {
      await axios.delete(`https://backend-api.pinkroses.sc.ke/api/delete-event/${event_id}`);
     setLoading(true)
      setEvents(events.filter(event => event.event_id !==event_id));
    } catch (error) {
      setError('Error deleting event');
    }
  };

  // Handle adding a new event
  const handleAddEvent = (newEvent) => {
    setEvents([...events, newEvent]);
  };

  // Handle updating an event
  const handleUpdateEvent = (updatedEvent) => {
    setEvents(events.map(event => event.id === updatedEvent.id ? updatedEvent : event));
  };

  // Toggle the create form visibility
  const toggleCreateForm = () => {
    setShowCreateForm(!showCreateForm);
  };

  // Open edit form with selected event data
  const openEditForm = (event) => {
    setCurrentEvent(event);
    setShowEditForm(true);
  };

  // Toggle the edit form visibility
  const toggleEditForm = () => {
    setShowEditForm(!showEditForm);
    setCurrentEvent(null); // Clear current event when closing the form
  };

  if (loading && showLoading) return (
    <div className="loading-container">
      <img src={loadingGif} alt="Loading..." className="loading-gif" />
    </div>
  );
  if (error) return <p>{error}</p>;

  return (
    <div className="admin-container">
      <h1>Admin Page</h1>
      <div className="btn-position">
      <button onClick={toggleCreateForm}>
        {showCreateForm ? 'Cancel' : 'Add New Event'}
      </button>
      </div>
  

      {showCreateForm &&
        <NewsEventCreateForm
          onClose={toggleCreateForm}
          onEventAdded={handleAddEvent}
        />
      }

      {showEditForm && currentEvent &&
        <NewsEventEditForm
          event={currentEvent}
          onClose={toggleEditForm}
          onEventUpdated={handleUpdateEvent}
        />
      }

      <h2>Event List</h2>
      <table className="event-table">
        <thead>
          <tr>
            <th>Title</th>
            <th>Description</th>
            <th>Date</th>
            <th>Image</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {events.map(event => (
            <tr key={event.id}>
              <td>{event.title}</td>
              <td>{event.description}</td>
              <td>{new Date(event.date).toLocaleDateString()}</td>
              <td>
                <img src={event.image} alt={event.title} className="event-image" />
              </td>
              <td>
                
                <button className="btn btn-danger" onClick={() => handleDeleteEvent(event.event_id)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AdminPage;
