import React from 'react'

import './Alumni.css'

import EnquireButton from '../../components/EnquireButton/EnquireComponent'
import AlumniApplicatioForm from '../../components/Modal/AlumniApplicatioForm'
let Alumni = () => {
  return (
   <>
      <div className='alumni container'>
         <div className="alumni-txt">
         <h1>Alumni</h1>
         </div> 
        </div>
        <div className="career-info">
  <p>
          Feel free to join our alumni network. We organize networking events for our alumni to share work and
          entrepreneurial experiences. We also accept requests from our alumni for mentorship opportunities
          and career talks.
      <br/>
  </p>
</div>

        <AlumniApplicatioForm/>
        <EnquireButton/>


   </>
  )
}

export default Alumni