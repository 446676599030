import React from "react";
import innovationImage from "../../../assests/Home/75.jpg";
import "./SmartBonesComponent.css";

const InnovationComponent = () => {
  return (
    <div className="history-s">
      <div className="history-left-s">
        <img className="smart-img" src={innovationImage} alt="Modern Learning Materials image" />
      </div>
      <div className="history-right-s">
        <h1>Modern Learning Materials</h1>
        <p>
          Modern learning materials have transformed educational
          environments by integrating innovative tools and technologies. From
          interactive smartboards and immersive virtual reality simulations to
          adaptive learning platforms and digital textbooks, these resources
          cater to diverse learning styles and enhance engagement. Students
          benefit from personalized learning experiences that foster deeper
          understanding and critical thinking skills, preparing them for the
          complexities of today's interconnected world.
        </p>
        <p>
          Beyond the classroom smart boards extend the learning experience to
          Pink Roses' botanical garden, where students engage in hands-on
          activities such as grafting cuttings, propagating new plants, and
          observing growth patterns. By integrating smart boards technology,
          Pink Roses cultivates essential skills such as critical thinking,
          observation, and experimentation, nurturing students' passion for
          science and nature.
        </p>
        <p>
          These advanced materials not only facilitate dynamic lessons in
          subjects like science, mathematics, and humanities but also promote
          collaborative learning and creativity. By leveraging modern learning
          materials, educators can tailor instruction to individual student
          needs, ensuring that learning is meaningful, relevant, and accessible
          to all.
        </p>
      </div>
    </div>
  );
};

export default InnovationComponent;
