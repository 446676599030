import React, { useState } from 'react'
import Hero from '../FrontImage/Hero'

import './HomeComponents.css'
import SchoolHistoryComponent from '../../SchoolHistoryComponent/SchoolHistoryComponent'
import Programs from '../Programs/programs'
import Titles from '../Titles/Titles'
import DirectorMessageComponent from '../DirectorMessageCompont/DirectorMessageComponent'
import InnovationComponent from '../InnovationSection/InnovationComponent'
import SmartBonesComponent from '../InnovationSection/SmartBonesComponent'
import ContactUs from '../../../pages/Contacts/ContactUs'
import EnquireButton from '../../EnquireButton/EnquireComponent'
import HomeTitles from '../Titles/HomeTitles'
import VideoPlayer from '../VideoPlayer/VideoPlayer'
import Lines from '../Lines/Lines'
const HomeComponents = () => {

  const [playerState, setPlayerState] = useState(false)
  return (
    <>
<div className='home-component'>
  <Hero/>
  <SchoolHistoryComponent setPlayerState={setPlayerState}/>
  <VideoPlayer playerState = { playerState}  setPlayerState ={setPlayerState}/>
  <HomeTitles  title=" Our Directors Message"/>
  <DirectorMessageComponent/>
  <InnovationComponent/>
  <SmartBonesComponent/>
  <Titles title="Join Pink Roses Schools" />
  <Lines/>
  
  <Programs/>
  <ContactUs/>
 <EnquireButton/>

  </div>
 
  </>
  )
}

export default HomeComponents    