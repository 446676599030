import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NavBar from './components/Home/NavBar/Navbar';
import Footer from './components/Footer/Footer'
import HomeComponents from "./components/Home/HomeItemsComponents/HomeComponents";
import About from "./pages/About/About";
import Careers from "./pages/Careers/Careers";
import OurSchool from "./pages/SchoolLife/Ourschool";
import AdmissionProcess from "./pages/AdmissionProcess/AdmissionProcess";
import WhyChooseUs from "./pages/WhyChooseUs/WhyChooseUs";
import Boarding from "./pages/Boarding/Boarding";
import Preschool from "./pages/PreSchool/Preschool";
import PrimarySchool from "./pages/PrimarySchool/PrimarySchool";
import JuniorSecondarySchool from "./pages/JuniorSecondarySchool/JuniorSecondarySchool";
import Alumni from "./pages/Alumni/Alumni";
import Enquire from "./pages/Enquire/Enquire";
import SchoolPhilosophy from "./pages/SchoolPhilosophy/SchoolPhilosophy";
import TermsAndServices from "./components/TermsAndServices/TermsAndServices";
import OnlineApplication from "./pages/OnlineApplication/OnlineApplication";
import NewsEvents from "./pages/NewsEvents/News";
import Admin from "./pages/Admin/AdminPage";
import NotFound from "./pages/NotFound/NotFound";

const App = () => (
  <Router>
    <NavBar />
    <Routes>
      <Route path="/" element={<HomeComponents />} />
      <Route path="/our-school/who-we-are" element={<About />} />
      <Route path="/our-school/careers" element={<Careers />} />
      <Route path="/our-school/new-events" element={<OurSchool />} />
      <Route path="/school/admission-process" element={<AdmissionProcess />} />
      <Route path="/school/why-choose-us" element={<WhyChooseUs />} />
      <Route path="/school/academics/preschool" element={<Preschool />} />
      <Route path="/school/academics/primary-school" element={<PrimarySchool />} />
      <Route path="/school/academics/junior-secondary-school" element={<JuniorSecondarySchool />} />
      <Route path="/school/boarding" element={<Boarding />} />
      <Route path="/school/alumni" element={<Alumni />} />
      <Route path="/our-school/enquire-anything" element={<Enquire />} />
      <Route path="/school/online-application" element={<OnlineApplication />} />
      <Route path="/school/our-philosophy" element={<SchoolPhilosophy />} />
      <Route path="/school/privacy-security" element={<TermsAndServices />} />
      <Route path="/school/newscreate" element={<NewsEvents />} />
      <Route path="/school/hbvbeb2hou4u5432209hgjt/admin" element={<Admin />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
    <Footer />
  </Router>
);

export default App;
