import React,{useState} from 'react'
import './WhyChooseUsComponent.css'
import {SliderComponent} from '../UniversalSliderComponent/SliderComponent'
import { slides } from '../../sliderData/carouselData'
import {content} from '../Cardsdata/data.json'
import { FaUser, FaTree, FaDollarSign, FaLaptop, FaChalkboardTeacher, FaTools } from 'react-icons/fa';
import { GiFarmer } from 'react-icons/gi';

const reasons = [
  { icon: <FaUser />, name: 'Moulding the character', description: 'We are committed to developing confident and disciplined pupils and students. Our partnership with all stakeholders is guided by our core values; Compassion, Respect, Integrity, Responsibility and Excellence'},
  { icon: <FaTree />, name: 'Serene Environment', description: 'We are located in a beautiful green environment away from town center. The refreshing greenery is conducive and stimulates learning.' },
  { icon: <FaDollarSign />, name: 'Play Zones', description: 'We have dedicated play areas accommodating all pupils and students. The play spaces enable our children to relax, explore and develop talents in line with CBC curriculum.' },
  { icon: <GiFarmer />, name: 'School Farm', description: 'We grow our own food which ensures our children eat fresh and well balanced food. The farm also has animals which enable for agricultural practical.' },
  { icon: <FaLaptop />, name: 'Digital learning', description: 'We appreciate our world today is interconnected and opportunities come alive in the digital spaces. We endeavor to incorporate digital technology as a way of life in our learning process.From content creation, curriculum delivery and presentations, our students have the right exposure to modern technology.' },
  { icon: <FaChalkboardTeacher />, name: 'Qualified Staff', description: 'A dedicated and well trained staff enable our pupils and students achieve full academic potential. Our staff are hired through an elaborate selection process that ensures for fairness.' },
  { icon: <FaTools />, name: 'Well Equipped facilities', description: 'The school boosts of modern classrooms with digital facilities. The new laboratory block is well equipped for science subjects content delivery.' }
]; 
const WhyChooseUsComponent = () => {
  const [expanded, setExpanded] = useState(Array(reasons.length).fill(false));

  const toggleReadMore = (index) => {
    setExpanded(expanded.map((item, i) => (i === index ? !item : item)));
  };

  return (
    <div className="why-choose-us-container">
      <h1>Why Choose Us</h1>
      <div className="reasons-container">
        {reasons.map((reason, index) => (
          <div className="reason-card" key={index}>
            <div className="reason-icon">{reason.icon}</div>
            <h2 className="reason-name">{reason.name}</h2>
            <p className="reason-description">
              {expanded[index] ? reason.description : `${reason.description.substring(0, 100)}...`}
              {reason.description.length > 100 && (
                <span className="read-more" onClick={() => toggleReadMore(index)}>
                  {expanded[index] ? ' Read Less' : ' Read More'}
                </span>
              )}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WhyChooseUsComponent