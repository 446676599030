import React, { useRef } from 'react'
import video from  '../../../assests/videos/school_v.mp4'
import  './VideoPlayer.css'
const VideoPlayer = ({playerState , setPlayerState}) => {

  const player = useRef(null)

  const closePlayer = (e)=>{
    if(e.target === player.current){ 
      setPlayerState(false)
    }
  }
  return (

   
    <div className={`video-player  ${playerState? '':'hide'}`} ref={player} onClick={closePlayer}>
        <video src={video} autoPlay muted controls> </video>
    </div>
  )
} 

export default VideoPlayer