import React from 'react'
import './ValuesComponents.css'

import Excellence from '../../../assests/icons/growth.png'
import Integrity from '../../../assests/icons/honesty.png'
import Responsibility from '../../../assests/icons/responsibility.png'
import Respect from '../../../assests/icons/respect.png'
import Compassion from '../../../assests/icons/charity.png'

const Values = () => {
  return (
    <>

    <div className="all-value">
    <h1>Our Values</h1>
    <div className="sections">
     
      <div className="value">
       <img src={Compassion} alt='icon'  className='card-image'/>
       <p> We treat everyone equally irrespective of the background they come from.</p>
       <h3>Compassion</h3>
      </div>
    
     
      <div className="value">
       <img src={Respect} alt='icon'  className='card-image'/>
       <p> We value, appreciate and hold you in high esteem. Everybody’s opinion is important
        and we will listen to your suggestions objectively</p>
       <h3>Respect</h3>
      </div>
     
      <div className="value">
       <img src={Integrity} alt='icon'  className='card-image'/>
       <p> We are honest and adhere to strong moral and ethical principles.</p>
       <h3>Integrity</h3>
      
      </div>
  
      <div className="value">
       <img src={Responsibility} alt='icon'  className='card-image'/>
       <p>We are all accountable for our actions. We have a duty of leaving Pink Roses a
          better place than we found it.</p>
       <h3>Responsibility</h3>
      </div>
  
      <div className="value">
       <img src={Excellence} alt='icon'  className='card-image'/>
       <p>We are created for greatness and we stand shoulder high with pride of our
         achievements.</p>
       <h3>Excellence</h3>
        </div>
      </div>
      </div>
    </>
  
  )
}

export default Values