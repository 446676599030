import React from "react";
import "./Preschool.css";
import image_1 from "../../assests/Home/c102.jpg";
import image_2 from "../../assests/Home/c118.jpg";
import image_3 from "../../assests/Home/c138.jpg";
import whychooseUsImage from "../../assests/kids_co-curricular_activities_images/e_image.JPG";

import PreSchoolTeacherImage from "../../assests/kids_co-curricular_activities_images/P-5-pre-sch-teacher.jpg";
import EnquireComponent from "../../components/EnquireButton/EnquireComponent";
import { GiH2O } from "react-icons/gi";
const Preschool = () => {
  return (
    <>
      <div className="preschool container">
        <div className="about-preschool">
          <h2> Welcome to Our Pre-school </h2>
        </div>
      </div>
      <div className="open-text-h2">
        <h2>Pink Roses Kindergarten Kiambu</h2>
        <p>
          We strive to offer the best learning foundation for our pupils. We
          understand that an enabling environment will help our young learners
          adjust to a new world of learning and enjoy school life. We are keen
          to provide learning material relevant to this age group as well as
          nutritious meals to give them energy for learning activities.
        </p>
      </div>
      <div className="history">
        <div className="history-left">
          <img className="smart-img" src={whychooseUsImage} alt="" />
        </div>
        <div className="history-right">
          <h2>Why Choose Our Pre-School</h2>
          {/* <div className="line"/> */}
          <p>Kindergarten School pupils at our institution benefit from:</p>
          <li>Committed and friendly teaching staff</li>
          <li>Academic and social trips to make learning fun</li>
          <li>School transport is provided</li>
          <li>Learning materials are provided at the school</li>
          <li>
            Religious studies are taught to lay a strong Christian foundation
          </li>
          <li>
            Exposure to IT skills. School has invested in tablets to be used by
            our young learners
          </li>
        </div>
      </div>

      <div className="open-text-20">
        <h2>Pre-School Curriculum</h2>
        <p>
          The Pre-School curriculum follows the CBC education system where
          learners are encouraged to develop their talents from young age.
          Learners are exposed to practicals that encourage learning and open up
          their thinking and reasoning.
        </p>
        <p>
          Teaching aids are utilized to stimulate learning and learners are
          exposed to IT skills as a foundation of digital learning. Through
          play-based learning, guided exploration, and collaborative activities,
          we foster their curiosity and empower them to discover their place
          within the world. Our programme emphasizes social inclusion, group
          work, and communication skills, ensuring that every child feels valued
          and supported as they embark on their educational journey at Pink
          Roses Schools Kindergarten.
        </p>
      </div>

      <div className="pre-choose-us">
        <div className="pre-left-section-choose">
          <h2>Dedicated Teaching Staff</h2>

          <p>
            Our teaching staff are well selected professionals who not only
            teach our young ones but also act as guardians who gently instruct
            the learners for the best learning experience. The staff accompany
            the learners for their meals, field activities, school trips and
            teach them on toilet experience.  Regular mentorship ensures
            personalized guidance, promoting holistic growth and a strong sense
            of belonging within the community.
          </p>
        </div>
        <div className="pre-right-section-choose">
          <img
            className="smart-img"
            src={PreSchoolTeacherImage}
            alt=" images in this area"
          />
        </div>
      </div>

      <div className="open-text-20">
        <h2>Extra-Curriculum Activities</h2>
        <p>
          To complement preschool learning, our captivating extracurricular
          programme at Pink Roses Schools offers a diverse range of exciting,
          skills-based experiences. Our aim is to provide our young learners
          with opportunities to explore their interests, develop new skills, and
          foster a love for learning beyond the classroom. In addition to our
          regular curriculum, we offer a variety of clubs and afternoon
          activities designed to engage our youngest child in a casual and
          social setting. These activities not only help children acclimatize to
          the early learning environment but also encourage collaboration,
          creativity, and social interaction.
        </p>
        <p>Some of the additional Kindergarten activities we offer include:</p>
      </div>

      <div className="images">
        <div className="image_1">
          <img src={image_1} alt="image_1" />
          <h3>Skating games</h3>
        </div>

        <div className="image_2">
          <img src={image_2} alt="image_2" />
          <h3>Football games</h3>
        </div>
        <div className="image_3">
          <img src={image_3} alt="image_3" />
          <h3>Martial Arts</h3>
        </div>
      </div>

      <div className="open-text-3">
        <h2>Additional Services Offered at Our Kindergarten</h2>
        <p>
          We offer catechism and bible classes for our young learners to raise
          them in according to our guiding Christian principles. We also teach
          music classes to expose the learners to various music instruments.
          Outdoor activities include visits to school farm, social trips to
          places of interests and also swimming lessons. Guidance and
          counselling sessions also help build the character of our learners.
        </p>
      </div>

      <EnquireComponent />
    </>
  );
};

export default Preschool;
