 import React,{useState} from 'react'
import './ContactUs.css'

import mailIcon from "../../assests/icons/icons8-email-100.png"
import callIcon from '../../assests/icons/icons8-call-100.png'
import locationIcon from '../../assests/icons/icons8-location-100.png'
import arrowIcon from '../../images/arrow_lgs.svg'
import axios from 'axios'
const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    message: '',
  });

  const [result, setResult] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setResult("Sending...")
    try {
      const response = await axios.post('https://backend-api.pinkroses.sc.ke/api/generalmesage/send', formData);
    
      if (response.status === 201) {
        setResult('Your opinion submitted successfully!');
        // Reset form data
        setFormData({
          name: '',
          phone: '',
          email: '',
          message: '',
        });
      }
    } catch (error) {
      setResult('Failed to submit your opinion,check your network!');
    }
  };

  return (
      
    <div className="contact">
    <div className="contact-call">
        <h2>Send us  a message   <img src="" alt="" /> </h2>
        <p>
            Feel free to reach out through contact form or find our contact 
            information below. Your feedback,questions, suggestions are
            important to us as we strive to provide exceptional service to your child.
        </p>

        <ul>
        
            <li><img src={mailIcon} alt="" /> pinkrosesschools@gmail.com </li>
            <li><img src={mailIcon} alt="" />director@pinkroses.sc.ke</li>
          
            <li><img src={callIcon} alt="" /> 0771 640924/ 0735 411 965</li>
            <li><img src={locationIcon} alt="" />
                 Location: Along Kiambu-Ngewa Road, Kiambu County</li>
         </ul>
    </div>
    <div className="contact-call">
        <form onSubmit={handleSubmit }>
            <label>Your name</label>
            <input type='text' name='name' placeholder='Enter your name.'onChange={handleChange} required />
            <label>Phone Number</label>
            <input type='tel' name='phone' placeholder='Enter mobile number'onChange={handleChange} required />
            <label>Email</label>
            <input type='email' name='email' placeholder='Enter your email'onChange={handleChange} required />
            <label>Write your message here.</label>
            <textarea name='message' rows="6" placeholder='Enter your message. 'onChange={handleChange} required></textarea>
            <button type='submit' className='btn-1 dark-btn '>Submit Now </button>
        </form>
        <span>{result} </span>

    </div>
    </div>

  )
}

export default ContactUs 