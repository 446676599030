import React, { useState } from 'react';
import './Careers.css';
import JobApplicationForm from '../../components/Modal/ApplyJobForm';

import EnquireComponent from '../../components/EnquireButton/EnquireComponent'

const Careers = () => {
  return (
    <>
      <div className="about container">
        <div className="about-us">
          <h1>Our careers</h1>
        </div>
      </div>

<div className="career-info">
<h1>Our School Careers</h1>
  <p>
          Thank you for your interest in joining Pink Roses Academy.
          We conduct a transparent and fair recruitment process. 
          We also conduct background checks and expect
          you to provide references we can engage to get more details about you.
      <br/>

          Please note we receive numerous applications for limited opportunities. We will keep your CV in our
          database in case we don’t contact you for an opening. We only contact applicants whose applications
          we wish to pursue and apologize for that.
          <br/>
          <br/>
          <br/>
          Apply for one of our roles at Pink Roses Academy
          Please add your details below and attach your CV and cover letter in the attachments section.
          <br/>
           We will get back to you as soon as possible.
  </p>
</div>
  <JobApplicationForm />
  <EnquireComponent />
      
    </>
  );
};

export default Careers;
