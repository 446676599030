import React from "react";
import "./EnquireComponent.css";
import { Link } from "react-router-dom";
const EnquireComponent = () => {
  return (
    <>
      <div className="enquire">
        <div>
          <h1>Contact us Today</h1>
          <div className="container">
            <hr className="line-1" />
          </div>
          <p>
            Find out how we can develop your child’s skills and expand their
            horizons for a life of success.{" "}
          </p>
        </div>

        <Link to="/our-school/enquire-anything">
        <button className="btn" type="submit">
          <h5>Enquire Now</h5>
        </button>
        </Link>
        
      </div>
    </>
  );
};

export default EnquireComponent;
