import React from "react";
import "./DirectorMessageComponent.css";

//images
import DirectorImage from "../../../assests/all-schools-images/P-2.jpg";

const DirectorMessageComponent = () => {
  return (
    <div className="Message">
      <div className="imageSection">
        <img src={DirectorImage} alt="" />
      </div>

      <div className="messageSection">
        <p>
          "At Pink Roses Schools, we value each and every child and seek to
            provide a happy and conducive environment for our pupils and
            students to learn and grow. We believe that everyone can flourish,
            develop their talents/skills and become contributing citizens. Our
            focus on the holistic development of pupils and students is
            encapsulated in the school mission “Dedicated to molding an
            emotionally intelligent individual who is able to face the academic
            challenges of today and tomorrow” as well as the school core values
            of CRIRE, namely, Compassion, Responsibility, Integrity, Respect
            and Excellence. Our Vision is to be the school of choice in the
            provision of academic excellence.
        </p>
        <p>
        We are proud of our team of committed teachers who ensure that no
        child is left behind. As a fraternity, we are committed to develop “A
        Flourishing School with Happy and Confident Learners” through the
        integration of Positive Education and building pupils’ sense of
        meaning and purpose through positive emotions and engagement.
        We have invested in state of art labs, modern teaching aids and
        integrated IT in delivering our lessons.
        </p>
        <p>
        We wish to thank all parents and stakeholders for your belief in the
        school and your support. We will continue to engage our stakeholders
        to make Pink Roses the school of choice.
        </p>

        <p>
        On behalf of the entire Pink Roses community, I wish to welcome you
        to Pink Roses Group of Schools."
        </p>
        <h5>Caroline Nderitu</h5>
        <h5>Director</h5>
      </div>
    </div>
  );
};
export default DirectorMessageComponent;
