import React from 'react'
import ContactUs from '../Contacts/ContactUs'
import EnquireComponent from '../../components/EnquireButton/EnquireComponent'

const Enquire = () => {
  return (

    <>
        <ContactUs/>
        <EnquireComponent/></>

  )
}

export default Enquire