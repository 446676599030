import React from "react";
import "./Boarding.css";
import image_1 from "../../assests/Dorm/184.jpg";
import image_2 from "../../assests/Dorm/168.jpg";
import image_3 from "../../assests/Dorm/190.jpg";

import WhyChooseImg from "../../assests/Dorm/189_.jpg";
import BoardingActivitiesImg from "../../assests/Dorm/304.jpg";
import BoardingMealsImg from "../../assests/Dorm/309.jpg";
import EnquireComponent from "../../components/EnquireButton/EnquireComponent";
const Boarding = () => {
  return (
    <>
      <div className="boarding container">
        <div className="about-boarding">
          <h1>Our Boarding</h1>
        </div>
      </div>
      <div className="open-text-1">
        <h2>Boarding School in Kiambu, Kenya</h2>
        <p>
          Our Boarding facilities are well developed purposely to create a
          homely environment which stimulates learning. From dormitory cubicles,
          hot shower, games, matrons, school clinic, more learning hours to well
          prepared meals, our boarding offers a great experience and conducive
          environment for learning and developing social skills.
        </p>
      </div>
      <div className="ex-images">
        <img src={image_1} alt="image_1" />
        <img src={image_2} alt="image_2" />
        <img src={image_3} alt="image_3" />
      </div>
      <div className="open-text-2">
        <h2>Tailored Boarding Experience</h2>
        <p>
          Welcome to Pink Roses Schools, where every student&#39;s journey is
          crafted with care and precision. Our tailored boarding experience
          ensures that each individual&#39;s unique needs, interests, and
          aspirations are met with personalized attention and support. From
          academic excellence to holistic development, we provide a nurturing
          environment where students thrive and flourish. With dedicated
          mentors, state-of- the-art facilities, and a vibrant community, Pink
          Roses Schools offers more than education; we offer a pathway to
          success tailored just for you.
        </p>
      </div>
      <div className="choose-boarding">
        <div className="left-part-choose">
          <img src={WhyChooseImg} alt=" hello" />
        </div>
        <div className="right-part-choose">
          <h2>Why choose our Boarding School?</h2>
          <p>
            Structured Environment: Our boarding school provide a highly
            structured environment that fosters discipline and routine. This
            structure helps students develop good time management and
            organizational skills, preparing them effectively for future
            challenges.
          </p>
          <p>
            Academic Focus: With dedicated study time and fewer distractions, we
            often achieve high academic standards. Teachers are readily
            available outside of class hours for extra help, fostering a
            supportive learning environment.
          </p>
          <p>
            Extracurricular Opportunities: we offer a wide range of
            extracurricular activities such as sports, arts, and clubs. These
            activities help students discover and develop their talents,
            promoting holistic development.
          </p>
          <p>
            Community and Lifelong Friendships: Living together builds strong
            bonds among students, creating a close-knit community. Boarding
            school alumni often maintain lifelong friendships and professional
            networks that prove valuable throughout their lives.
          </p>
          <p>
            Independence and Responsibility: the boarding school encourage
            independence and self-reliance as students manage their daily lives
            away from home. This experience helps them mature emotionally and
            socially, preparing them for the challenges of adulthood.
          </p>
          <p>
            Cultural and Global Awareness: we have diverse student from various
            backgrounds and countries. This diversity enriches students&#39;
            understanding of different cultures and perspectives, preparing them
            to thrive in a globalized world.
          </p>
          <p>
            Personal Growth and Character Development: Living independently at a
            boarding school challenges students to confront and overcome
            obstacles, fostering resilience and character development. They
            learn to manage setbacks and adapt to new situations, contributing
            to their personal growth.
          </p>
        </div>
      </div>

      <div className="choose-boarding">
        <div className="left-part-choose">
          <h2>Boarding Activities</h2>
          <p>
            On weekends, boarders have the opportunity to engage in a diverse
            array of outdoor activities. From sports tournaments to swimming,
            cinema outings to theatre visits, and culturally enriching trips led
            by teachers, there&#39;s something for everyone to enjoy during
            leisure time. Our clubs, including karate, soccer academy, coding,
            financial models and indoor sports center, are available for access
            under the supervision of qualified instructors, ensuring a safe and
            enjoyable experience for all. Regular Boarding School assemblies are
            held to address any concerns and celebrate birthdays and the
            remarkable achievements of our students. Each member of our
            residential community is assigned a mentor who provides personalized
            support and guidance. Boarding mentors coordinate pastoral care,
            liaise with academic staff to ensure homework completion, and
            monitor the overall well-being and progress of students in their
            chosen education journey.
          </p>
        </div>
        <div className="right-part-choose">
          <img
            className="boardImg"
            src={BoardingActivitiesImg}
            alt=" images in this area"
          />
        </div>
      </div>

      <div className="choose-boarding">
        <div className="left-part-choose">
          <h2>Meals and other Services</h2>

          <p>
            We provide nutritious and well balanced meals to our children. Most
            of the food is grown on the school farm and Children&#39;s
            preferences are regularly considered to ensure mealtime
            satisfaction. Our healthy, and delicious meals are prepared with
            care to cater to the diverse tastes and dietary needs of our
            students. Breakfast, lunch, and dinner are served in the welcoming
            ambiance of the Boarding House Dining Hall outside of school hours,
            providing a communal space for students to enjoy their meals
            together. In addition to our focus on nutrition and well-being, the
            Boarding School also offers convenient laundry facilities, ensuring
            that students have clean clothes and linens throughout their stay.
            Our commitment to holistic care extends beyond academics to
            encompass every aspect of student life, fostering a supportive and
            nurturing environment where students can thrive. We have well
            stocked school clinic manned by a qualified nurse. The school has a
            well serviced generator which ensures power is available throughout.
            The school borehole ensures constant supply of clean water.
            Moreover, Sunday church services are organized, providing an
            opportunity for spiritual reflection and community engagement. These
            services contribute to the holistic development of our students,
            nurturing their spiritual well-being alongside their academic and
            personal growth.
          </p>
        </div>
        <div className="right-part-choose">
          <img src={BoardingMealsImg} alt=" images in this area" />
        </div>
      </div>

      <div className="open-text-3">
        <h2>Security at our Boarding School</h2>
        <p>
          The Boarding houses at Pink Roses Schools are nestled within the
          secure confines of our campus. Our fully enclosed facility ensures the
          safety and security of our students and staff, with round-the-clock
          support from our dedicated security team. At Pink Roses Schools, we
          prioritize the well-being of our community, providing a nurturing
          environment where students can focus on their academic and personal
          growth without any concerns about safety.
        </p>
      </div>

      <EnquireComponent />
    </>
  );
};

export default Boarding;
