import React from "react";
import innovationImage from "../../../assests/lab_images/300.jpg";
import "./innovationComponents.css";

const InnovationComponent = () => {
  return (
    <>
      <div className="innovationSection">
        <div className="section-left">
          <h1>Expanding the Bounds of Learning with Modern Labs</h1>
          {/* <div className="line"/> */}
          <p>
            At Pink Roses School, our modern laboratories are
            equipped with cutting-edge technology to provide students with
            immersive, hands-on learning experiences. Whether exploring biology,
            chemistry, or physics, each lab is furnished with state-of-the-art
            equipment and resources that support comprehensive scientific
            exploration.{" "}
          </p>
          <p>
            Under the guidance of experienced educators, students actively
            participate in interactive experiments and research projects. These
            practical experiences enable them to apply theoretical knowledge in
            real-world contexts, deepening their understanding of scientific
            principles.{" "}
          </p>

          <p>
            Moreover, our labs foster interdisciplinary collaboration,
            encouraging students from various disciplines to work together on
            challenging projects. Through collaborative efforts, students
            develop critical thinking, communication, and teamwork skills
            essential for navigating an increasingly complex global landscape.{" "}
          </p>
        </div>

        <div className="section-right">
          <img src={innovationImage} alt="" />
        </div>
      </div>
    </>
  );
};

export default InnovationComponent;
