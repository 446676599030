import React from "react";
import "./SchoolPhilosophy.css";
import EnquireComponent from "../../components/EnquireButton/EnquireComponent";
const SchoolPhilosophy = () => {
  return (
    <>
      <div className="philosophy container">
        <div className="our_philosophy">
          <h1>Our School Philosophy</h1>
        </div>
      </div>
      <div className="school_philosophy">
        <h1>Our School Philosophy</h1>
        <p>
          We believe in giving all children an equal opportunity to excel in
          their studies. The founding Directors’ vision was to create an
          enabling environment where all pupils and students are appreciated,
          motivated and encouraged to realize their full potential.
        </p>

        <p>
          At Pink Roses, every child is set at equal footing and treated with
          respect and love. We believe in molding individuals who are well
          rounded, independent and ready to face challenges beyond Pink Roses
          thereby contributing to personal and societal development.
        </p>
        <p>
          We are dedicated to providing quality, affordable education and a safe
          learning environment for all learners. Our goal is to help our
          children to become confident, independent learners and to have the
          best possible experience in our school.
        </p>
      </div>
      <EnquireComponent />
    </>
  );
};

export default SchoolPhilosophy;
