export const EducationItems = [
   

  {
    title: 'Why Choose Us',
    path: '/school/why-choose-us',
    cName: 'dropdown-link'
  },
 
    {
      title: 'Kindergarten',
      path: '/school/academics/preschool',
      cName: 'dropdown-link'
    },
    {
      title: 'Primary School',
      path: '/school/academics/primary-school',
      cName: 'dropdown-link'
    },
    
    {
      title: 'Junior Secondary School',
      path: '/school/academics/junior-secondary-school',
      cName: 'dropdown-link'
    }
   
  
  ];