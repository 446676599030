import React from "react";
import "./Footer.css";
import facebook from "../../assests/socials/facebook.png";
import Pin from "../../assests/logos/pin.png";
import instagram from "../../assests/socials/instagram.png";
import linkedin from "../../assests/socials/linkedin.png";
import logo from "../../assests/logos/footer-logo.jpeg";
import tiktok from '../../assests/socials/tiktok.png'
import { Link } from "react-router-dom";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="footer">
      <div className="sb__footer section__padding">
        <div className="sb__footer-links">
          <div className="sb__footer-links_div">
            <img src={logo} className="logo-image" alt="School logo" />
          </div>
          <div className="sb__footer-links_div">
            <h4>PINK ROSES SCHOOLS</h4>
            <div className="contacts">
              <p>0771 640924</p>
              <p>0735 411 965</p>
              <p>pinkrosesschools@gmail.com</p>
              <p>director@pinkroses.sc.ke</p>
            </div>
          </div>
          <div className="sb__footer-links_div">
            <h4>LOCATION</h4>
            <div className="pin">
              <p>Along Kiambu</p>
              <p>Ngewa Road</p>
              <a
                href="https://www.google.com/maps/place/THE+PINK+ROSES+ACADEMY/@-1.201295,36.0683158,10z/data=!4m21!1m14!4m13!1m5!1m1!1s0x182f3113951e993f:0x9f13597d06ad473b!2m2!1d36.84!2d-1.09!1m5!1m1!1s0x182f3113951e993f:0x9f13597d06ad473b!2m2!1d36.84!2d-1.09!3e0!3m5!1s0x182f3113951e993f:0x9f13597d06ad473b!8m2!3d-1.09!4d36.84!16s%2Fg%2F11pf1y61nz?entry=ttu"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={Pin} alt="Map pin" />
              </a>
            </div>
          </div>
          <div className="sb__footer-links_div">
            <h4>HELPFUL LINKS</h4>
            <a href="https://pra.mzizi.co.ke/ISIMSLogin.aspx" target="_blank" rel="noopener noreferrer">
              <p>Student Portal</p>
            </a>
            <a href="https://pra.mzizi.co.ke/ISIMSLogin.aspx" target="_blank" rel="noopener noreferrer">
              <p>Parent Portal</p>
            </a>
            <Link to="/school/admission-process">
              <p>Admission</p>
            </Link>
            <Link to="/our-school/careers">
              <p>Careers</p>
            </Link>
          </div>
          <div className="sb__footer-links_div">
            <h4>SOCIAL MEDIA</h4>
            <div className="socialmedia">
              <a href="https://www.facebook.com/thepinkrosesacademy" target="_blank" rel="noopener noreferrer">
                <img src={facebook} alt="Facebook" />
                <p>Facebook</p>
              </a>
              <a href="#" target="_blank" rel="noopener noreferrer">
                <img src={linkedin} alt="LinkedIn" />
                <p>LinkedIn</p>
              </a>
              <a href="#" target="_blank" rel="noopener noreferrer">
                <img src={instagram} alt="Instagram" />
                <p>Instagram</p>
              </a>
              <a href="https://www.tiktok.com/@pinkrosesschools" target="_blank" rel="noopener noreferrer">
                <img src={tiktok} alt="tiktok" />
                <p>Tiktok</p>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="sb__footer-below">
        <h3>Called to Excel</h3>
        <Link to="#">
          <p>Terms & Conditions</p>
        </Link>
        <Link to="/school/privacy&security">
          <p>Privacy & Security</p>
        </Link>
        <p>&copy; {currentYear} Pink Roses Schools. All rights reserved.</p>
      </div>
    </div>
  );
};

export default Footer;
