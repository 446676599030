export const AdmissionItems = [
  
    {
      title: 'Admission process',
      path: '/school/admission-process',
      cName: 'dropdown-link'
    },
    {
      title: 'Online Application',
      path: '/school/online-application',
      cName: 'dropdown-link'
    },
  
  ];


  // {
  //   title: 'Why Choose Us',
  //   path: '/why-choose-us',
  //   cName: 'dropdown-link'
  // },