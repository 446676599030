import React from 'react'

import './WhyChooseUs.css'
import WhyChooseUsComponent from '../../components/WhyChooseAsComponent/WhyChooseUsComponent'
import EnquireComponent from '../../components/EnquireButton/EnquireComponent'
const Admission = () => {
  return(
    <>
       <div className='why-choose-us container'>
      <div className="choose-us">
      <h1>Why Choose Us</h1>
      </div> 
     </div> 
     <WhyChooseUsComponent />

     <EnquireComponent/>

     </>
     
 )
   
}

export default Admission