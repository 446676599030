import React from "react";
import "./AdmissionComponent.css";
import { Link } from 'react-router-dom';
const AdmissionComponent = () => {
  return (
    <>
      <div className="admission_process">
        <div className="step_1">
        <h1>Application</h1>
          <p>
            Complete and online application form, call or email us for
            enquiries.
          </p>
          <Link to ="/school/online-application">
          <button className="apply-btn">Apply online</button></Link>
         
        </div>
        <div className="step_2">
          <h1>Confirmation of slot.</h1>
          <p>We will confirm availability of a slot in the desired class.</p>
          </div>
          <div className="step_3">
          <h1>Placement Assessment</h1>
          <p> We invite your child to undertake a placement assessment and results
            of the assessment will be communicated to you same day.</p>
          </div>
          <div className="step_4">
          <h1>Registration money</h1>
          <p>
            You will be required to pay admission fee to enable us reserve the
            slot. We send you admission form or you can download it from this
            site. Upon returning the admission form, we will begin the
            onboarding process and ensure your child is well settled in the new
            environment.
          </p>
          </div>
          <div className="step_5">
          <h1>Admission</h1>
          
          <p>Your child is admitted to Pink Roses Schools.</p>
          
          </div>
     
      
</div>
    
    </>
  );
};

export default AdmissionComponent;
