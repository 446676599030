import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Card.css';

// Import the loading GIF
import loadingGif from '../../../assests/videos/loading.gif'; // Adjust the path if necessary

const Card = () => {
  const [expandedId, setExpandedId] = useState(null);
  const [newsEvents, setNewsEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showLoading, setShowLoading] = useState(false); // State to control when to show loading
  const [error, setError] = useState(null);

  const toggleReadMore = (id) => {
    setExpandedId(expandedId === id ? null : id);
  };

  useEffect(() => {
    let timeoutId; // To hold the timeout ID for clearing it if needed

    const fetchNewsEvents = async () => {
      try {
        // Simulate a delay before showing the loading spinner
        timeoutId = setTimeout(() => setShowLoading(true), 500); // Adjust the delay as needed

        const response = await axios.get('https://backend-api.pinkroses.sc.ke/api/news-events'); // Adjust the URL to match your API endpoint
        setNewsEvents(response.data);
      } catch (error) {
        setError('Error fetching news events');
      } finally {
        setLoading(false);
        setShowLoading(false); // Hide loading spinner once data is fetched
        clearTimeout(timeoutId); // Clear the timeout if request is completed before delay
      }
    };

    fetchNewsEvents();

    // Cleanup function to clear the timeout if the component unmounts before the timeout completes
    return () => clearTimeout(timeoutId);
  }, []);

  if (loading && showLoading) return (
    <div className="loading-container">
      <img src={loadingGif} alt="Loading..." className="loading-gif" />
    </div>
  );

  if (error) return <p>{error}</p>;

  return (
    <div className="container-1">
      <h1>News and Events</h1>
      <div className="card-container-1">
        {newsEvents.length === 0 ? (
          <h4>No events to show</h4>
        ) : (
          newsEvents.map((newsEvent) => (
            <div className="card" key={newsEvent.id}>
              <img
                src={newsEvent.image || 'https://via.placeholder.com/150'}
                alt={newsEvent.title}
                className="card-image"
              />
              <h2 className="card-title">{newsEvent.title}</h2>
              <p className="card-description">
                {expandedId === newsEvent.id ? newsEvent.description : `${newsEvent.description.substring(0, 100)}...`}
                {newsEvent.description.length > 100 && (
                  <span className="read-more" onClick={() => toggleReadMore(newsEvent.id)}>
                    {expandedId === newsEvent.id ? ' Read Less' : ' Read More'}
                  </span>
                )}
              </p>
              <p className="card-date">{new Date(newsEvent.date).toLocaleDateString()}</p>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default Card;
