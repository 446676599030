import React from 'react'
import  './programs.css'
import { Link } from 'react-router-dom'
//images for the programs
import PreschoolImg  from  '../../../assests/Home/165.jpg'
import PrimaryschoolImg  from  '../../../assests/Home/155.jpg'
import SecondaryschoolImg  from  '../../../assests/Home/home.jpg'
import KindergartenIcon  from  '../../../images/kid_1_icon.jpg'
const Programs = () => {
    return (
        <>
    
        <div className='programs'>
         <div className="program">
         <Link to="/school/academics/preschool">
            <img src={PreschoolImg} alt='kindergarten'/>
            <div className="caption">
                <img src={KindergartenIcon} alt=''/>
                <p>Pre-School</p>
            </div> 
           
              <button>Pre-School</button>
            </Link>
         </div>

         <div className="program">
    <Link to="/school/academics/primary-school">
      <img src={PrimaryschoolImg} alt='Primary school'/>
      <div className="caption">
        <img src={KindergartenIcon} alt=''/>
        <p>Primary School</p>
      </div>
      
        <button>Primary School</button>
      </Link>
    </div>

         <div className="program">
         <Link to="/school/academics/junior-secondary-school">
            <img src={SecondaryschoolImg} alt='Junior Secondary School'/>
            <div className="caption">
                <img src={KindergartenIcon} alt=''/>
                <p>Junior Secondary School</p>
            </div>
            
            <button>Junior Secondary School</button>
            </Link>
         </div>
        </div>
        </>
    )
}

export default Programs