import React from "react";
import "./PrimarySchool.css";
import image_1 from "../../assests/ps/89.jpg";
import WhyChooseImage from "../../assests/ps/158.jpg";
import SchoolLifeImg from "../../assests/ps/136.jpg";
import ChessImg from "../../assests/ps/193.jpg";
import TourImg from "../../assests/kids_co-curricular_activities_images/d_image.JPG";
import FootballImg from "../../assests/ps/197.jpg";
import EnquireComponent from "../../components/EnquireButton/EnquireComponent";
const PrimarySchool = () => {
  return (
    <>
      <div className="prischool container">
        <div className="about-prischool">
          <h1> Welcome to Our Primary School</h1>
        </div>
      </div>
      <div className="primary-text-1">
        <h2>Pink Roses Primary School in Kiambu</h2>
        <p>
          Our Primary school has consistently attracted Pupils from across the
          country due to its strong academic and discipline foundation. Guided
          by Christian Principles, we have created a school where learners are
          encouraged to explore and develop their God- given abilities. Set up
          in a leafy area of Kiambu County, we have the best learning
          environment for our learners.
        </p>
      </div>
      <div className="pri-choose-us">
        <div className="left-section-pri">
          <img src={WhyChooseImage} alt=" img" />
        </div>
        <div className="why-choose-pri">
          <h2>Why choose our Primary School?</h2>
          <p></p>
          <li>
            Quality of Teaching: The school employs qualified and experienced
            teachers who are adept at engaging young learners, fostering
            curiosity, and promoting a love for learning.
          </li>
          <li>
            Curriculum: A well-rounded curriculum that covers core subjects
            (such as language arts, mathematics, science, and social studies)
            alongside opportunities for physical education, arts, and
            extracurricular activities
          </li>
          <li>
            Learning Environment: A safe, supportive, and inclusive environment
            where children feel valued and respected which is critical for their
            emotional and social development.
          </li>
          <li>
            Resources and Facilities: Access to up-to-date educational
            resources, including books, technology, and facilities like
            libraries, playgrounds, and sports areas which enhances the learning
            experience.
          </li>
          <li>
            Parental Involvement: we encourage and facilitate parental
            involvement in the learning journey
          </li>
          <li>
            Assessment and Feedback: Regular assessment of students&#39;
            progress and timely feedback to both students and parents help
            identify strengths and areas needing improvement.
          </li>
          <li>
            Support Services: Availability of support services such as
            counseling, special education programs, and health services can
            ensure that all students receive the assistance they need to thrive
            academically and emotionally.
          </li>
        </div>
      </div>

      <div className="primary-text-2">
        <h2> Primary School Curriculum</h2>
        <p>
          Our Primary School programme emphasizes social inclusion, group work,
          communication skills and Guidance and Counselling ensuring that every
          Pupil feels valued and supported as they embark on their educational
          journey at Pink Roses Primary School.
        </p>
        <p>
          Our Pupils are expected to develop their talents alongside the
          academic pursuits ensuring a well- rounded individual capable of
          facing modern day challenges. Our pastoral care ensure pupils are well
          grounded in their Christian faith. Pupils are also expected to learn a
          foreign language, participate in clubs and games.
        </p>
        <p>
          We have introduced financial literacy classes as well as coding
          classes to our pupils to expose them to the changing world and also
          prepare them to future careers.
        </p>
      </div>

      <div className="pri-choose-section">
        <div className="pri-left-section-choose">
          <h2>Primary School Life</h2>
          <p>
            At Pink Roses Primary School, School life is a vibrant tapestry of
            learning, growth and social interaction. It encompasses a rich array
            of experiences where students engage in academic pursuits,
            extracurricular activities, and personal development. In classrooms,
            students delve into diverse subjects, challenging their intellect
            and fostering curiosity. Beyond academics, school life includes
            sports, arts, and clubs, providing avenues for talent exploration
            and teamwork. Interactions with peers and teachers nurture social
            skills and empathy, shaping students&#39; character and resilience.
            School life is not just about education; it&#39;s about forging
            memories, building friendships, and preparing for a future filled
            with opportunities and achievements.{" "}
          </p>
        </div>
        <div className="pri-right-section-choose">
          <img src={SchoolLifeImg} alt=" images in this area" />
        </div>
      </div>

      <div className="primary-text-2">
        <h2>Extra-Curriculum Activities</h2>
        <p>
          To Complement Primary School learning, the school offers clubs, games
          and academic tours for learning and relaxation. Our pupils participate
          in drama, debates and science congress to spur learning. We take our
          pupils to cultural centers to learn and appreciate our heritage. We
          organize academic tours to institutions such as universities, research
          centers and wildlife conservancies to expose them to various facets of
          learning.
        </p>
        <p>
          Some of the additional Primary School activities we offer include:
        </p>
      </div>

      <div className="images">
        <div className="image_1">
          <img src={ChessImg} alt="image_1" />
          <h3>Chess games</h3>
        </div>

        <div className="image_2">
          <img src={TourImg} alt="image_2" />
          <h3>Educational Tours</h3>
        </div>
        <div className="image_3">
          <img src={FootballImg} alt="image_3" />
          <h3>Football games</h3>
        </div>
      </div>

      <div className="primary-text-3">
        <h2>Additional Services Offered at Our Primary</h2>
        <p>
          Pink Roses School is nestled within the secure confines of our gated
          campus in Kiambu. Our fully enclosed facility ensures the safety and
          security of our Pupils, Students and Staff, with round-the-clock
          support from our dedicated security team. At Pink Roses Schools, we
          prioritize the well-being of our community, providing a nurturing
          environment where students can focus on their academic and personal
          growth without any concerns about safety.
        </p>
      </div>

      <EnquireComponent />
    </>
  );
};

export default PrimarySchool;
