import React, { useState } from 'react';
import './OnlineApplicationForm.css';
import logo from '../../assests/logos/best_logo_.png';
import axios from 'axios';

const OnlineApplicationForm = () => {
  const [formData, setFormData] = useState({
    fname: '',
    lname: '',
    date: '',
    email: '',
    grade: '',
    parentName: '',
    contactNumber: '',
  });

  const [result, setResult] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setResult("Sending...")
    try {
      const response = await axios.post('https://backend-api.pinkroses.sc.ke/api/admision-application-form/submit', formData);
      if (response.status === 201) {
        setResult('Application submitted successfully!');
        // Reset form data
        setFormData({
          fname: '',
          lname: '',
          date: '',
          email: '',
          grade: '',
          parentName: '',
          contactNumber: '',
        });
      }
    } catch (error) {
      setResult('Failed to submit application.');
    }
  };

  return (
    <div className="application-form">
      <img src={logo} alt="Pink Roses Schools Logo" className="logo" />
      <h2>Online Registration Form</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="fname">First Name</label>
          <input
            type="text"
            id="fname"
            name="fname"
            value={formData.fname}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="lname">Second Name</label>
          <input
            type="text"
            id="lname"
            name="lname"
            value={formData.lname}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="grade">Class/Grade interested to join</label>
          <input
            type="text"
            id="grade"
            name="grade"
            value={formData.grade}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="date">Planned start date</label>
          <input
            type="date"
            id="date"
            name="date"
            value={formData.date}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="parentName">Parent/Guardian Name</label>
          <input
            type="text"
            id="parentName"
            name="parentName"
            value={formData.parentName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="contactNumber">Contact Number</label>
          <input
            type="text"
            id="contactNumber"
            name="contactNumber"
            value={formData.contactNumber}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit">Register</button>
      </form>
      <span>{result}</span>
    </div>
  );
}

export default OnlineApplicationForm;
