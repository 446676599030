import React from "react";
import { Link } from "react-router-dom";

import "./JuniorSecondarySchool.css";
import image_1 from "../../assests/jsc/81.jpg";
import image_2 from "../../assests/jsc/303.jpg";
import image_3 from "../../assests/jsc/301.jpg";
import WhyChooseImage from "../../assests/jsc/a.jpg";
import ComputerStudiesImage from "../../assests/jsc/89.jpg";
import FinacialStudiesImage from "../../assests/jsc/00.png";
import MusicStudiesImage from "../../assests/jsc/guiter.avif";
import JscLabs from "../../assests/lab_images/jss.jpg"; 

import EnquireComponent from "../../components/EnquireButton/EnquireComponent";
const JuniorSecondarySchool = () => {
  return (
    <>
      <div className="jssschool container">
        <div className="about-jssschool">
          <h1> Welcome to Our Junior Secondary School</h1>
        </div>
      </div>
      <div className="open-text-1">
        <h2>Junior Secondary School in Kiambu</h2>
        <p>
          CBC curriculum introduced the Junior Secondary School (JSS) to prepare
          learners for High School. JSS is a secondary curriculum where learners
          are exposed to STEM (Science, Engineering, Technology and Mathematics)
          subjects that focus on practical learning, to develop the necessary
          skills in students so that they are prepared for the
          rapidly-developing real world. Teaching STEM subjects require
          investment in ICT and Science labs which we have put in place to aid
          learning.
        </p>
      </div>
      <div className="juni-choose-section">
        <div className="juni-why-section-choose">
          <img src={WhyChooseImage} alt=" hello" className="whychoose-image" />
        </div>
        <div className="juni-why-choose-section">
          <h2>Why choose our Junior Secondary School?</h2>
          <p>Junior Secondary School pupils at our institution benefit from:</p>
          <li>Equipped laboratories</li>
          <li>Academic support</li>
          <li>Cultural outings in Nairobi</li>
          <p>Exclusive access to premium facilities including:</p>
          <li>Computer studies</li>
          <li>Coding/programming classes</li>
          <li>Financial Skills/ literacy education</li>
          <li>Career talks</li>
          <li>Guidance and Counselling Sessions</li>
          <p>
            Engaging in community life, pupils develop lifelong friendships
            while learning discipline, independence, and respect in a nurturing
            environment. Under constant supervision from experienced educators,
            they form lasting bonds and acquire essential life skills. Regular
            mentorship ensures personalized guidance, fostering holistic growth
            and nurturing a sense of belonging within our school community.
          </p>
        </div>
      </div>

      <div className="open-text-2j">
        <h2> Junior Secondary School Curriculum</h2>
        <p>
          Our learners are expected to develop their social and academic skills
          by participating in class practicals, arts, music, games and clubs
          within the school. We offer holistic learning by developing children
          who are academically prepared by integrating Christian values with
          learner’s academic pursuits..
        </p>
        <p>
          Our programme emphasizes social inclusion, group work and
          communication skills, ensuring that every student feels valued and
          supported as they embark on their educational journey at Pink Roses
          Junior Secondary School.
        </p>
      </div>
      <div className="juni-choose-us">
        <div className="juni-left-section-choose">
          <h2>Well Equipped JSS Labs</h2>
          <p>
            Our well-equipped laboratories at Junior Secondary School provide
            students with a dynamic environment for hands-on learning and
            scientific exploration. These state-of-the-art facilities are
            tailored to support various disciplines, including Biology,
            Chemistry, Physics and computer studies. Students actively engage in
            practical experiments, research endeavors, and critical thinking
            exercises guided by experienced instructors. Access to modern
            equipment and resources allow students to gain valuable insights and
            proficiency in applying theoretical knowledge to real-world
            scenarios. These labs not only enhance academic learning but also
            cultivate curiosity, innovation, and a passion for discovery among
            our students.
          </p>
          <p>
            Other facilities include music lab, home science lab, Arts Room,
            Library and Health Center.
          </p>
        </div>
        <div className="juni-right-section-choose">
          <img src={JscLabs} alt=" images in this area" />
        </div>
      </div>

      <div className="open-text-20">
        <h2>Extra-Curriculum Activities</h2>
        <p>
          Our JSS students get exposed to cultural activities by participating
          in community activities where they learn cultural values. Agricultural
          studies in the school farm expose learners to crop cultivation and
          animal husbandry. We have active clubs which include; Scouts,
          Environmentalists, Karate, Soccer Academy, gymnastics, Financial
          literacy and Coding Classes.
        </p>
        <p>
          Students can participate in games such as soccer, basketball, net
          ball, hockey, volleyball and board games.
        </p>
        <p>
          Some of the additional Junior Secondary activities we offer include:
        </p>
      </div>
      <div className="images">
        <div className="image_3">
          <img src={image_1} alt="image_3" />
          <h3>Computer Studies</h3>
        </div>

        <div className="image_3">
          <img src={image_2} alt="image_3" />
          <h3>Cooking Practicals</h3>
        </div>

        <div className="image_3">
          <img src={image_3} alt="image_3" />
          <h3>Agriculture Studies</h3>
        </div>
      </div>

      <div className="open-text-3">
        <h2>Additional Services Offered at Our Junior Secondary</h2>
        <div className="images">
          <div className="image_3">
            <img src={ComputerStudiesImage} alt="image_3" />
            <h3>Computer Programming</h3>
          </div>

          <div className="image_3">
            <img src={FinacialStudiesImage} alt="image_3" />
            <h3>Financial literacy</h3>
          </div>
          <div className="image_3">
            <img src={MusicStudiesImage} alt="image_3" />
            <h3>Playing music Instuments</h3>
          </div>
        </div>
      </div>

      <EnquireComponent />
    </>
  );
};

export default JuniorSecondarySchool;
