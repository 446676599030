import React from 'react';
import './Ourschool.css';
import OnlineApplicationForm from '../../components/Modal/OnlineApplicationForm';
import ContactUs from '../Contacts/ContactUs';
import EnquireComponent from '../../components/EnquireButton/EnquireComponent';
import Card from './card/Card';

const Ourschool =()=> {
  return (
    <>
  <div className='school-life container'>
    <div className='school-life-txt'>
        <h1>NEWS AND EVENTS</h1>
       
    </div>
</div>

<Card/>
<EnquireComponent/>
    </>
  );
}

export default Ourschool ;