import React from 'react';
import './Button.css';
import { Link } from 'react-router-dom';


const Button =() => {
  return (
<Link to ="/our-school/enquire-anything" >
        <button className='btn'> Enquire</button>
</Link>
   
  )
}

export default Button
