import React, { useState } from 'react';

import {EducationItems} from '../../Menus/EducationItems';
import { Link } from "react-router-dom";
import '../../../components/Home/Dropdown/Dropdown.css'


 const EducationDropDown = ()=> {
 const [click, setClick] = useState(false);
 const handleClick = () => setClick(!click);

  return (
 <>
  <ul
        onClick={handleClick}
        className={click ? 'dropdown-menu clicked' : 'dropdown-menu'}
      >
        {EducationItems.map((item, index) => {
          return (
            <li key={index}>
              <Link
                className={item.cName}
                to={item.path}
                onClick={() => setClick(false)}
              >
                {item.title}
              </Link>
            </li>
            
          );
        })}
      </ul>
 </>
  )
}

export default EducationDropDown

