
import React from 'react';
import './NotFound.css'; // Optional, for styling

const NotFound = () => {
  return (
    <div className="not-found">
      <h1> Page 404 !</h1>
    
    </div>
  );
};

export default NotFound;
